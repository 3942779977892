import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import WakacjeOferty from "../components/wakacjeOferty"

const SecondPage = () => (
  <Layout pageInfo={{ pageName: "wakacje2024" }}>
    <SEO title="Wakacje 2024"/>
    <WakacjeOferty />
  </Layout>
)

export default SecondPage
